import React from 'react'

export const Recommendations = () => {
    return (
        <div className='sales-section'>
            <div className='recommendations grid'>
                <div className='recommendations_carousel_content'>
                    <div className='text-center'>
                        <h5 className='recommendations_carousel_content_heading font-xsmall'>Recommendations</h5>
                    </div>
                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className='text-center'>
                                    <p className='recommendations_carousel_content_first_line font-medium'>“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae sed aliquam quisque nec nec sit urna, gravida id. Nulla in velit varius fusce tincidunt”</p>
                                    <p className='recommendations_carousel_content_secoond_line font-xsmall'>Genie Botha - Customer</p>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='text-center'>
                                    <p className='recommendations_carousel_content_first_line font-medium'>“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae sed aliquam quisque nec nec sit urna, gravida id. Nulla in velit varius fusce tincidunt”</p>
                                    <p className='recommendations_carousel_content_secoond_line font-xsmall'>Genie Botha - Customer</p>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='text-center'>
                                    <p className='recommendations_carousel_content_first_line font-medium'>“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae sed aliquam quisque nec nec sit urna, gravida id. Nulla in velit varius fusce tincidunt”</p>
                                    <p className='recommendations_carousel_content_secoond_line font-xsmall'>Genie Botha - Customer</p>
                                </div>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
