export function formatPhoneNumber(
  phoneNumberString: string,
  includeZero = false,
) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  if (includeZero) {
    cleaned = '0' + cleaned.slice(2, cleaned.length)
    match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return match[1] + ' ' + match[2] + ' ' + match[3]
    }
  } else {
    if (cleaned[0] === '0') {
      cleaned = '27' + cleaned.slice(1, cleaned.length)
    }
    var match = cleaned.match(/^(\d{2})(\d{2})(\d{3})(\d{4})$/)
    if (match) {
      return match[1] + ' ' + match[2] + ' ' + match[3] + ' ' + match[4]
    }
  }
  return null
}
