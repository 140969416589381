import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { Routes } from './routes';
import './App.css';


//fonts
import "./assets/fonts/AzoSans-Black.ttf";
import "./assets/fonts/AzoSans-BlackItalic.ttf";
import "./assets/fonts/AzoSans-Bold.ttf";
import "./assets/fonts/AzoSans-BoldItalic.ttf";
import "./assets/fonts/AzoSans-Italic.ttf";
import "./assets/fonts/AzoSans-Light.ttf";
import "./assets/fonts/AzoSans-LightItalic.ttf";
import "./assets/fonts/AzoSans-Medium.ttf";
import "./assets/fonts/AzoSans-MediumItalic.ttf";
import "./assets/fonts/AzoSans-Regular.ttf";
import "./assets/fonts/AzoSans-Thin.ttf";
import "./assets/fonts/AzoSans-ThinItalic.ttf";

function App() {
  return (
    <BrowserRouter >
      <Switch>
        <Routes />
      </Switch>
    </BrowserRouter >
  );
}

export default App;
