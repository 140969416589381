import React from 'react';
import { Modal } from 'react-bootstrap';
// import{ videoLink } from "../assets/images/what_is_go121/demo_video_2.mp4";

interface Props {
    show: boolean;
    onHide?: () => void;
}

export const VideoPopup: React.FC<Props> = (props) => {
    return (
        <Modal show={props.show} onHide={props.onHide} dialogClassName="modal-90w" centered>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <iframe width="100%" height="500px" src="https://www.youtube.com/embed/DRKopCS-S6w" title="YouTube video player" frameBorder="0" className='i_frame_styls' allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Modal.Body>
        </Modal>
    );
};
