import React from 'react'
import { Recommendations } from '../../components/verify-an-angent-success/recommendations'
import { SiphoIsTrained } from '../../components/verify-an-angent-success/sipho-is-trained'
import { VerifyAnAngentHeaderSuccess } from '../../components/verify-an-angent-success/verify-an-angent-header-success'
import { StuckWeHereToHelp } from '../../components/verify-an-agent/stuck-we-here-to-help';
import { SalesAgent } from '../../components/become-an-agent/sales-agent';


export const VeriffyAnAgentSuccess = () => {
    return (
        <div>
           <VerifyAnAngentHeaderSuccess/>
           <SiphoIsTrained/>
           <Recommendations/>
           <StuckWeHereToHelp />
            <SalesAgent />
        </div>
    )
}
