import React from 'react'
import { CorporatePartner } from '../../components/become-a-partner/corporate-partner';
import { CreateJobs } from '../../components/become-a-partner/create-jobs';
import { Header } from '../../components/become-a-partner/header';
import { OurBiggestBrands } from '../../components/become-a-partner/our-biggest-brands';
import { PowerfulSalesTool } from '../../components/become-a-partner/powerful-sales-tool';
import { ThreeEasySteps } from '../../components/become-a-partner/three-easy-steps';
import { HereHelp } from '../../components/become-an-agent/here-help';

export const BecomeaPartner = () => {
    return (
        <div>
            <Header/>
            <CreateJobs/>
            <PowerfulSalesTool/>
            <ThreeEasySteps/>
            <OurBiggestBrands/>
            <HereHelp faqGUID={process.env.REACT_APP_BECOME_PARTNER_FAQ_GUID}/>
            <CorporatePartner/>
        </div>
    )
}
