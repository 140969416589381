import React from 'react'
// import groupFramesImg from '../../assets/images/what_is_go121/group_frames.png';
// import groupFramemobileViewImg from '../../assets/images/what_is_go121/group_frames_mobile_pic.png';
import iconOneImg from '../../assets/images/what_is_go121/icon1.png';
import iconTwoImg from '../../assets/images/what_is_go121/icon2.png';
import iconThreeImg from '../../assets/images/what_is_go121/icon3.png';
import iconFourImg from '../../assets/images/what_is_go121/icon4.png';
import iconFiveImg from '../../assets/images/what_is_go121/icon5.png';
import iconSixImg from '../../assets/images/what_is_go121/icon6.png';
import iconSevenImg from '../../assets/images/what_is_go121/icon7.png';
import iconEightImg from '../../assets/images/what_is_go121/icon8.png';

export const ProductsAndServices = () => {
    return (
        <div className='header-section-white'>
            <div className='products_and_services grid'>
                <div className='products_and_services_content1'>
                    <p className='products_and_services_content1_first_line font-xsmall'>products and services</p>
                    <h1 className='products_and_services_content1_second_line font-large'>We have a wide range of products and services</h1>
                    <p className='products_and_services_content1_third_line font-small'>And we are adding more all the time</p>
                </div>
                {/* <div className='products_and_services_content2'>
                    <img src={groupFramesImg} alt="loading" />
                </div>
                <div className='products_and_services_content2_mobile_view_pic'>
                    <img src={groupFramemobileViewImg} alt="loading" />
                </div> */}
                <div className='products_and_services_icons_mobile_view_div'>
                    <div className='products_and_services_icons_mobile_view_div_first_row'>
                        <div className='icon_one'>
                            <img src={iconOneImg} alt="loading" />
                        </div>
                        <div className='icon_two'>
                            <img src={iconTwoImg} alt="loading" />
                        </div>
                    </div>
                    <div className='products_and_services_icons_mobile_view_div_second_row'>
                        <div className='icon_three'>
                            <img src={iconThreeImg} alt="loading" />
                        </div>
                        <div className='icon_four'>
                            <img src={iconFourImg} alt="loading" />
                        </div>
                    </div>
                    <div className='products_and_services_icons_mobile_view_div_third_row'>
                        <div className='icon_five'>
                            <img src={iconFiveImg} alt="loading" />
                        </div>
                        <div className='icon_six'>
                            <img src={iconSixImg} alt="loading" />
                        </div>
                    </div>
                    <div className='products_and_services_icons_mobile_view_div_fourth_row'>
                        <div className='icon_seven'>
                            <img src={iconSevenImg} alt="loading" />
                        </div>
                        <div className='icon_eight'>
                            <img src={iconEightImg} alt="loading" />
                        </div>
                    </div>
                </div>
                <div className='products_and_services_icons_div'>
                    <div className='products_and_services_icons_div_first_row'>
                        <div className='icon_one'>
                            <img src={iconOneImg} alt="loading" />
                        </div>
                        <div className='icon_two'>
                            <img src={iconTwoImg} alt="loading" />
                        </div>
                        <div className='icon_three'>
                            <img src={iconThreeImg} alt="loading" />
                        </div>
                        <div className='icon_four'>
                            <img src={iconFourImg} alt="loading" />
                        </div>
                    </div>
                    <div className='products_and_services_icons_div_second_row'>
                        <div className='icon_five'>
                            <img src={iconFiveImg} alt="loading" />
                        </div>
                        <div className='icon_six'>
                            <img src={iconSixImg} alt="loading" />
                        </div>
                        <div className='icon_seven'>
                            <img src={iconSevenImg} alt="loading" />
                        </div>
                        <div className='icon_eight'>
                            <img src={iconEightImg} alt="loading" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
