import React from 'react'
import { NavLink } from 'react-router-dom';

export const GetStartedBtn = () => {
    return (
        <div className="header-section-white">
            <div className="get-starte-btn-row grid">
                <div className='get_starte_btn'>
                    <NavLink onClick={()=> {window.scrollTo({top:0, left:0, behavior:'smooth'})}} to="/help-centre" className='button_action btn_center'>View all FAQ's
                        <span className='btn-w ms-4'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 24" fill="none">
                            <path d="M4.88892 12H19.1111M19.1111 12L13.4971 6M19.1111 12L13.4971 18" stroke="#FFBF00" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        </span>
                    </NavLink>
                </div>
            </div>
        </div>
    )
}
