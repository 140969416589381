import React from 'react'
import { HelpCenterHeader } from '../../components/help-center/help-center-header';
import { SalesAgent } from '../../components/become-an-agent/sales-agent';
import { PopularSalesAgentBelowCards } from '../../components/help-center/popular-sales-agent-below-cards';
import { WhyBecomeAnAgent } from '../../components/help-center-topic/why-become-an-agent';

export const HelpCenterTopic = () => {
    return (
        <div>
            <HelpCenterHeader faqCategories={[]} />
            <WhyBecomeAnAgent />
            <PopularSalesAgentBelowCards />
            <SalesAgent />
        </div>
    )
}
