import React from 'react'
import SwippingMachineImg from '../../assets/images/help center article/swipping_machine_pic.png';
import MobileBgImg from '../../assets/images/help center article/mobile_bg_img.png';

export const TheBenifits = () => {
    return (
        <div className='header-section-white'>
            <div className='help_center_articale_the_benifits grid'>
                <div className='help_center_articale_the_benifits_first_row_left_side_img1'>
                    <img src={SwippingMachineImg} alt="loading" />
                </div>
                {/* <div className='help_center_articale_the_benifits_first_row_left_side_bg_img1'>
                    <img src={MobileBgImg} alt="loading" />
                </div> */}
                <div className='help_center_articale_the_benifits_first_row_right_side_content'>
                    <h4 className='help_center_articale_the_benifits_first_row_right_side_content_first_line font-medium'>The benefits</h4>
                    <div>
                        <ul className="list-inline d-flex">
                            <li className="dot_img_sizes"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 28" fill="none">
                                <circle cx="3.5" cy="14.5" r="3.5" fill="#055CF5" />
                            </svg></li>
                            <li className="help_center_articale_the_benifits_first_row_right_side_content_second_line font-small">Nunc, in quam cursus ipsum diam eget pharetra sed pulvinar.</li>
                        </ul>
                        <ul className="list-inline d-flex">
                            <li className="dot_img_sizes"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 28" fill="none">
                                <circle cx="3.5" cy="14.5" r="3.5" fill="#055CF5" />
                            </svg></li>
                            <li className="help_center_articale_the_benifits_first_row_right_side_content_second_line font-small">Nunc, in quam cursus ipsum diam eget pharetra sed pulvinar.</li>
                        </ul>
                        <ul className="list-inline d-flex">
                            <li className="dot_img_sizes"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 28" fill="none">
                                <circle cx="3.5" cy="14.5" r="3.5" fill="#055CF5" />
                            </svg></li>
                            <li className="help_center_articale_the_benifits_first_row_right_side_content_second_line font-small">Nunc, in quam cursus ipsum diam eget pharetra sed pulvinar.</li>
                        </ul>
                    </div>
                </div>
                <div className='help_center_articale_the_benifits_second_row_content'>
                    <h5 className='help_center_articale_the_benifits_second_row_content_first_line font-medium'>Lorem ipsum dolor sit amet, consectetur adipiscing</h5>
                    <p className='help_center_articale_the_benifits_second_row_content_second_line font-small'>Nunc, in quam cursus ipsum diam eget pharetra sed pulvinar. Tempus adipiscing sagittis vitae phasellus suspendisse risus. Habitant pellentesque feugiat turpis arcu. Amet vestibulum ipsum sem lobortis dictum augue sed felis. Varius dolor augue est congue semper egestas facilisis maecenas at. Proin cursus odio purus, velit adipiscing morbi. Id malesuada arcu penatibus vestibulum, ac.</p>

                    <p className='help_center_articale_the_benifits_second_row_content_second_line font-small'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo sit lacus lectus viverra scelerisque. Nunc, in quam cursus ipsum diam eget pharetra sed pulvinar. Tempus adipiscing sagittis vitae phasellus suspendisse risus. Habitant pellentesque feugiat turpis arcu. Libero, augue adipiscing libero tempor quis risus ipsum tristique semper.
                    </p>
                </div>
            </div>
        </div>
    )
}
