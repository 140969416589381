import React, { useEffect, useState } from 'react'
import FormMenImg from '../../assets/images/become an agent form/form_bg_desktop.png'
import FormMenImgMobile from '../../assets/images/become an agent form/form_bg_mobile.png'
import ReactCodeInput from 'react-verification-code-input'
import Notification, { NotificationState } from '../../components/notification'
import axios from '../../utils/api'
import { Link, useHistory } from 'react-router-dom'
import { formatPhoneNumber } from '../../utils/formatstring'

const proxyApi = process.env.REACT_APP_PROXY_API_URL
const Otp: React.FC = (props: any) => {
  const history = useHistory()
  const [agentStatus, setAgentStatus] = useState<string | null>('')
  const [code, setCode] = useState<string>('')
  const [otpCode, setOtpCode] = useState<string>('')
  const [agentID, setAgentID] = useState<string>('')
  const [otpID, setOtpID] = useState<string>('')
  const [isResentCode, setIsResentCode] = useState<boolean>(false)
  const [newApplication, setNewApplication] = useState<boolean>(false)
  const [loading, setIsLoading] = useState<boolean>(false)
  const [resendLoading, setIsResendLoading] = useState<boolean>(false)
  const [notification, setNotification] = useState<NotificationState>({
    text: '',
    type: 'success',
    visible: false,
  })

  useEffect(() => {
    let pAgentID = localStorage.getItem('agentId')
    let pCode = localStorage.getItem('code')
    let pOtpID = localStorage.getItem('otpId')
    setAgentStatus(localStorage.getItem('agentStatus'))
    if (!pAgentID || !pOtpID || !pCode) {
      props.history.push('/personal-details')
    } else {
      setNewApplication(localStorage.getItem('isNew') === 'true')
      setAgentID(pAgentID)
      setOtpID(pOtpID)
      setCode(pCode)
    }
  }, [])

  const handleSubmit = () => {
    setIsLoading(true)
    axios
      .post(`${proxyApi}api/proxy_post/Agent/${agentID}/VerifyOtp`, {
        otpId: otpID,
        pin: otpCode,
      })
      .then(({ data }) => {
        setIsLoading(false)
        if (data.isValid) {
          props.history.push('/address-details')
        } else {
          setNotification({
            text: 'Invalid OTP entered. Please try again.',
            type: 'warn',
            visible: true,
          })
        }
      })
      .catch((error) => {
        setIsLoading(false)
        if (error.response.status === 400) {
          setNotification({
            text: 'Invalid OTP entered. Please try again.',
            type: 'warn',
            visible: true,
          })
        } else {
          setNotification({
            text:
              "Sorry, we're experiencing problems with our system. Please try again in a few minutes.",
            type: 'warn',
            visible: true,
          })
        }
      })
  }

  const handleResendCode = () => {
    if (!resendLoading) {
      setIsResendLoading(true)
      axios
        .post(`${proxyApi}api/proxy_post/Agent/${code}/VerifyAgent`)
        .then(({ data }) => {
          setIsResentCode(true)
          setAgentID(data.agentId)
          setOtpID(data.otpId)
          setIsResendLoading(false)
        })
        .catch((error) => {
          setIsResendLoading(false)
          setNotification({
            text:
              "Sorry, we're experiencing problems with our system. Please try again in a few minutes.",
            type: 'warn',
            visible: true,
          })
        })
    }
  }

  const handleChangeCellphone = () => {
    history.push('/personal-details', {
      firstname: localStorage.getItem('username'),
      surname: localStorage.getItem('surname'),
      cellphone: localStorage.getItem('phoneNumber'),
    })
  }

  return (
    <div className="nabar-section">
      {notification.visible && (
        <Notification type={notification.type} text={notification.text} />
      )}
      <div className="otp_form grid">
        <div className="otp_form_left_side_form">
          <p className="otp_breadcrumb">
            <Link to={'/become-go-121-agent'}>
              <span className="otp_breadcrumb_first">
                Become an Agent
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M6 3L11 8L6 13"
                    stroke="#FF4600"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </Link>
            <Link to={'/personal-details'}>
              <span className="otp_breadcrumb_first">
                Personal details
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M6 3L11 8L6 13"
                    stroke="#FF4600"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </Link>
            <span className="otp_breadcrumb_second">Enter OTP</span>
          </p>
          <div className="otp_form_container">
            <div className="otp_form_header">
              <div className="otp_form_title">Enter OTP</div>
              <div className="otp_form_step">Step 2/3</div>
            </div>
            <div className="otp_form_body">
              <div className="otp_form_group">
                <p className="otp_form_description">
                  We’ve sent a one-time PIN to the cellphone number +
                  {formatPhoneNumber(localStorage.getItem('phoneNumber') || '')}
                </p>
              </div>
              <div className="otp_form_group">
                <ReactCodeInput
                  type="number"
                  fields={6}
                  className={`otp_input_container`}
                  onChange={(e) => setOtpCode(e)}
                />
              </div>
              <div className="otp_form_group">
                <span
                  className="otp_resend_btn"
                  onClick={() => handleResendCode()}
                >
                  Resend OTP{' '}
                  {resendLoading && (
                    <svg
                      width="100"
                      height="100"
                      viewBox="0 0 100 100"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50C100 22.3913 77.6087 0 50 0C22.3913 0 0 22.3913 0 50M8.47826 50C8.47826 27.1739 26.9565 8.47826 50 8.47826C73.0435 8.47826 91.5217 27.1739 91.5217 50"
                        fill="currentColor"
                      >
                        <animateTransform
                          attributeName="transform"
                          attributeType="XML"
                          type="rotate"
                          dur="1s"
                          from="0 50 50"
                          to="360 50 50"
                          repeatCount="indefinite"
                        />
                      </path>
                    </svg>
                  )}
                  {isResentCode && (
                    <span style={{ color: '#FF4600' }}>Sent!</span>
                  )}
                </span>
              </div>
              <div className="otp_form_group">
                <button
                  type="submit"
                  className="otp_form_submit_btn"
                  disabled={otpCode.length !== 6 || loading}
                  onClick={() => handleSubmit()}
                >
                  <span>Next</span>
                  {loading ? (
                    <svg
                      width="100"
                      height="100"
                      viewBox="0 0 100 100"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50C100 22.3913 77.6087 0 50 0C22.3913 0 0 22.3913 0 50M8.47826 50C8.47826 27.1739 26.9565 8.47826 50 8.47826C73.0435 8.47826 91.5217 27.1739 91.5217 50"
                        fill="white"
                      >
                        <animateTransform
                          attributeName="transform"
                          attributeType="XML"
                          type="rotate"
                          dur="1s"
                          from="0 50 50"
                          to="360 50 50"
                          repeatCount="indefinite"
                        />
                      </path>
                    </svg>
                  ) : (
                    <svg
                      width="22"
                      height="24"
                      viewBox="0 0 22 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.88867 12H19.1109M19.1109 12L13.4969 6M19.1109 12L13.4969 18"
                        stroke="#FFBF00"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </button>
              </div>
              <div className="otp_extra_info" hidden={!newApplication}>
                <span onClick={() => handleChangeCellphone()}>
                  Change cellphone number
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="otp_form_right_side_img">
          <img src={FormMenImg} alt="loading" />
        </div>
        <div className="otp_form_right_side_img_mobile">
          <img src={FormMenImgMobile} alt="loading" />
        </div>
      </div>
    </div>
  )
}

export { Otp }
