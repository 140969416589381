import React from 'react'
import ContactUpArrowImg from '../../assets/images/contact/contact_up_arrow.png'
import ContactHandWave from '../../assets/images/contact/wave-hand.png'
import ContactUpArrowImgMobile from '../../assets/images/contact/contact_arrowM.png'
import ContactHandWaveMobile from '../../assets/images/contact/wave-hand-mobile.png'

export const ContactHeader = () => {
  return (
    <div className="header-section">
      <div className="contact_page grid">
        <div className="contact_page_up_arrow_img">
          <img src={ContactUpArrowImg} alt="loading" />
        </div>
        <div className="contact_page_up_arrow_img_mobile">
          <img src={ContactUpArrowImgMobile} alt="loading" />
        </div>
        <div className="contact_page_heading">
          <h1 className="contact_page_first_line font-large">
            Need more<br className='contact_page_first_line_break' /> help?
            <br /> Give us a call.
          </h1>
        </div>
        <div className="contact_page_arrow_img">
          <img src={ContactHandWave} alt="loading" />
        </div>
        <div className="contact_page_arrow_img_mobile">
          <img src={ContactHandWaveMobile} alt="loading" />
        </div>
      </div>
    </div>
  )
}
