import React from "react";
import { FaqCategory } from "../../constant/types";

interface FaqCategoryProps extends FaqCategory {
    active?: boolean;
    onClick?: () => void;
}

const HelpCenterFaqCategoryButton: React.FC<FaqCategoryProps> = (props) => {
    return (
        <div
            onClick={() => props.onClick && props.onClick()}
            className={`help_center_header_faq_category_btn ${props.active ? 'active' : ''}`}
            style={{ backgroundColor: props.colourHexCode }}
        >
            {props.name}
        </div>
    );
};

export default HelpCenterFaqCategoryButton;