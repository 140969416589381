import React from 'react'
import carouselImg2 from '../../assets/images/home page images/carousel_imger2.png';
import carouselImg3 from '../../assets/images/home page images/carousel_imger3.png';
import BgImg from '../../assets/images/home page images/backgroundArrow.png';
import ScrollDownImg from '../../assets/images/home page images/scrolldown_img.png';
import ScrollDownImgT2 from '../../assets/images/home page images/scrolldown_imgT2.png'
import FirstImg from '../../assets/images/home page images/desk_tab_new_men_pic.png';
import mobileViewMenImg from '../../assets/images/home page images/mobile_view_men_pic.png';
import tablet2ViewMenImg from '../../assets/images/home page images/Image1T2.png';
import { NavLink } from 'react-router-dom';
// import BecomeAnAgentbtnImg from '../../assets/images/home page images/become_an_angent_btn.png';
import { Link } from 'react-router-dom';

export const WelcomeToGo = () => {
    return (
        <div className="header-section">
            <div className="header_welcome_to_go grid">
                <div id="carouselExampleIndicators" className="carousel slide carousel_section" data-bs-ride="carousel" data-interval="false">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className='grid carousel-grid'>
                                <div className="left-side-div">
                                    <p className="first-para font-xsmall">WELCOME TO GO121</p>
                                    <div className="text_content font-xlarge">Sell more on the go</div>
                                    <p className="second-para font-medium">Be your own boss and connect brands to customers in your community with Go121.</p>
                                    <NavLink onClick={()=> {window.scrollTo({top:0, left:0, behavior:'smooth'})}} className='button_action' to ="/become-go-121-agent">Become an Agent
                                        <span className='btn-w'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 24" fill="none">
                                            <path d="M4.88892 12H19.1111M19.1111 12L13.4971 6M19.1111 12L13.4971 18" stroke="#FFBF00" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        </span>
                                    </NavLink>
                                </div>
                                <div className="main-contant-image">
                                    <img src={FirstImg} alt="loading" className="headersMenImg" />
                                    <img src={mobileViewMenImg} alt="loading" className="mobile_view_headers_men_img" />
                                    <img src={tablet2ViewMenImg} alt="loading" className="tablet2_view_headers_men_img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="arrow-image">
                    <img src={BgImg} alt="loading" className="backGroundArrowImg" />
                </div>
                <div className="left-down-img">
                    <img src={ScrollDownImg} alt="loading" className="scrollDownImg" />
                    <img src={ScrollDownImgT2} alt="loading" className="scrollDownImgTablet2" />
                </div>
            </div>
        </div>
    )
}


// import React from 'react'
// import BgImg from '../../assets/images/home page images/backgroundArrow.png';
// import ScrollDownImg from '../../assets/images/home page images/scrolldown_img.png';
// import FirstImg from '../../assets/images/home page images/desk_tab_new_men_pic.png';
// import mobileViewMenImg from '../../assets/images/home page images/mobile_view_men_pic.png';
// // import BecomeAnAgentbtnImg from '../../assets/images/home page images/become_an_angent_btn.png';
// import { Link } from 'react-router-dom';

// export const WelcomeToGo = () => {
//     return (
//         <div className="header-section">
//             <div className="header_welcome_to_go grid">
//                 <div className="left-side-div">
//                     <p className="first-para font-xsmall">WELCOME TO GO121</p>
//                     <div className="text_content font-xlarge">Sell more on the go</div>
//                     <p className="second-para font-medium">Be your own boss and connect brands to customers in your community with Go121.</p>
//                     <button className='button_action'>Become an Agent
//                         <span className='btn-w ms-4'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 24" fill="none">
//                             <path d="M4.88892 12H19.1111M19.1111 12L13.4971 6M19.1111 12L13.4971 18" stroke="#FFBF00" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
//                         </svg>
//                         </span>
//                     </button>
//                 </div>
//                 <div className="left-down-img">
//                     <img src={ScrollDownImg} alt="loading" className="scrollDownImg" />
//                 </div>
//                 <div className="main-contant-image">
//                     <img src={FirstImg} alt="loading" className="headersMenImg" />
//                     <img src={mobileViewMenImg} alt="loading" className="mobile_view_headers_men_img" />
//                 </div>
//                 <div className="arrow-image">
//                     <img src={BgImg} alt="loading" className="backGroundArrowImg" />
//                 </div>
//             </div>
//         </div>
//     )
// }