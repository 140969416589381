import React from 'react'

export const JoinOurTeam = () => {
    return (
        <div className='about_us_bg_img'>
            <div className='about_us_join_our_team grid'>
                <div className='about_us_join_our_team_content'>
                    <p className='about_us_join_our_team_content_first_line font-xsmall'>Careers</p>
                    <h1 className='about_us_join_our_team_content_second_line font-large'>Join our team</h1>
                    <p className='about_us_join_our_team_content_three_line font-small'>Doing a job that creates jobs for others is very rewarding. Go 121 is always growing and is always looking for talent. Take a look at the positions we currently have open.</p>
                    <a href="https://za.linkedin.com/company/go121" target={"_blank"} className='button_action btn_center'>See available jobs
                        <span className='btn-w'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 24" fill="none">
                            <path d="M4.88892 12H19.1111M19.1111 12L13.4971 6M19.1111 12L13.4971 18" stroke="#FFBF00" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        </span>
                    </a>
                </div>
            </div>
        </div>
    )
}
