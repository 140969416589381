import React, { useState } from "react";
import CircleRightImg from "../../assets/images/become an agent/circle right.svg";
import BecomeAnAgentHeaderOnlyPersonImg from "../../assets/images/become an agent/new_men_pic.png";
import BecomeAnAgentHeaderOnlyPersonImgMobile from "../../assets/images/become an agent/new_men_picM.webp";
import { Link } from "react-router-dom";
import { FromPopup } from "../submit-details-popup";

export const BecomeAnAgentHeader = () => {
  const [showSubmitDetailsPopup, setShowSubmitDetailsPopup] = useState(false);
  const showSubmitPopup = () => {
    setShowSubmitDetailsPopup(!showSubmitDetailsPopup);
  };

  const isProd = process.env.REACT_APP_IS_CLIENT === "true";

  return (
    <>
      {showSubmitDetailsPopup && (
        <FromPopup
          show={showSubmitDetailsPopup}
          onHide={() => showSubmitPopup()}
        />
      )}
      {/* {showSubmitDetailsPopup && (<ThankYouPopup show={showSubmitDetailsPopup} onHide={() => showSubmitPopup()}/>)} */}
      <div className="header-section">
        <div className="become_an_agent_onw_boss grid">
          <div className="become_an_agent_onw_boss_left_side_content">
            <p className="become_an_agent_onw_boss_left_side_content_first_line font-xsmall">
              Become an agent
            </p>
            <h1 className="become_an_agent_onw_boss_left_side_content_second_line font-large become_an_agent_onw_boss_left_side_heading_font_size">
              A cell phone and a Go121 starter kit. That’s all you need to{" "}
              <span style={{ color: "#FFBF00" }}>be your own boss</span>
            </h1>
            <div className="become_an_agent_onw_boss_div">
              <ul className="list-inline">
                <li className="become_an_agent_onw_boss_left_side_content_third_line">
                  <ul className="list-inline d-flex">
                    <li className="img_padding_top">
                      <img src={CircleRightImg} alt="loaidng" />
                    </li>
                    <li className="become_an_agent_onw_boss_left_side_font_size font-medium">
                      Earn a living as an independent sales agent.
                    </li>
                  </ul>
                </li>
                <li className="become_an_agent_onw_boss_left_side_content_fouth_line">
                  <ul className="list-inline d-flex">
                    <li className="img_padding_top">
                      <img src={CircleRightImg} alt="loaidng" />
                    </li>
                    <li className="become_an_agent_onw_boss_left_side_font_size font-medium">
                      Feel empowered with free online training on all our
                      brands.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            {isProd ? (
              <>
                <div className="button_section">
                  <button
                    className="button_action become_an_agent_onw_boss_left_button become_an_agent_onw_boss_left_button_old"
                    onClick={() => showSubmitPopup()}
                  >
                    Interested? Submit your details
                    <span className="btn-w ms-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 22 24"
                        fill="none"
                      >
                        <path
                          d="M4.88892 12H19.1111M19.1111 12L13.4971 6M19.1111 12L13.4971 18"
                          stroke="#FFBF00"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </button>
                </div>

                <div className="become_agent_application_register">
                  <p className="m-0">
                    Applications are not yet open, but please register
                  </p>
                  <p className="m-0">
                    your details in the meantime and we’ll contact you.
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className="button_section">
                  <Link
                    to={"/personal-details"}
                    className="button_action become_an_agent_onw_boss_left_button"
                  >
                    Start application
                    <span className="btn-w ms-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 22 24"
                        fill="none"
                      >
                        <path
                          d="M4.88892 12H19.1111M19.1111 12L13.4971 6M19.1111 12L13.4971 18"
                          stroke="#FFBF00"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </Link>
                  <Link
                    to={"/cell-phone-input"}
                    className="button_action become_an_agent_onw_boss_right_button"
                  >
                    Carry on where I left off
                    <span className="btn-w ms-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 22 24"
                        fill="none"
                      >
                        <path
                          d="M4.88867 12H19.1109M19.1109 12L13.4969 6M19.1109 12L13.4969 18"
                          stroke="#FF1200"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </Link>
                </div>
              </>
            )}
          </div>
          <div className="become_an_agent_onw_boss_right_side_img">
            <img src={BecomeAnAgentHeaderOnlyPersonImg} alt="loading" />
          </div>
          <div className="become_an_agent_onw_boss_right_side_img_mobile">
            <img src={BecomeAnAgentHeaderOnlyPersonImgMobile} alt="loading" />
          </div>
        </div>
      </div>
    </>
  );
};
