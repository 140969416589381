import React from 'react'

export const WasThisArticaleHelpful = () => {
    return (
        <div className='header-section-white'>
            <div className='was_this_artical_helpful grid'>
                <div className='was_this_artical_helpful_center_content'>
                    <div className='was_this_artical_helpful_center_content_card'>
                        <h4 className='was_this_artical_helpful_center_content_first_line font-medinum'>Was this article helpful?</h4>
                        <p className='was_this_artical_helpful_center_content_second_line font-small'>We are always aiming to improve our service to you</p>
                        <div className='yes_and_no_btns'>
                            <span className='yes_btn'><svg xmlns="http://www.w3.org/2000/svg" width="241" height="50" viewBox="0 0 241 50" fill="none">
                                <rect width="241" height="50" rx="8" fill="#055CF5" fill-opacity="0.1" />
                                <path d="M113.246 25.828V30H115.178V25.828L118.678 20.074H116.508L114.282 24.008L112.056 20.074H109.788L113.246 25.828ZM119.653 25.772C119.695 25.142 120.227 24.414 121.193 24.414C122.257 24.414 122.705 25.086 122.733 25.772H119.653ZM122.915 27.564C122.691 28.18 122.215 28.614 121.347 28.614C120.423 28.614 119.653 27.956 119.611 27.046H124.539C124.539 27.018 124.567 26.738 124.567 26.472C124.567 24.26 123.293 22.902 121.165 22.902C119.401 22.902 117.777 24.33 117.777 26.528C117.777 28.852 119.443 30.21 121.333 30.21C123.027 30.21 124.119 29.216 124.469 28.026L122.915 27.564ZM125.305 28.082C125.389 28.866 126.103 30.21 128.175 30.21C129.981 30.21 130.849 29.062 130.849 27.942C130.849 26.934 130.163 26.108 128.805 25.828L127.825 25.618C127.447 25.548 127.195 25.338 127.195 25.002C127.195 24.61 127.587 24.316 128.077 24.316C128.861 24.316 129.155 24.834 129.211 25.24L130.765 24.89C130.681 24.148 130.023 22.902 128.063 22.902C126.579 22.902 125.487 23.924 125.487 25.156C125.487 26.122 126.089 26.92 127.419 27.214L128.329 27.424C128.861 27.536 129.071 27.788 129.071 28.096C129.071 28.46 128.777 28.782 128.161 28.782C127.349 28.782 126.943 28.278 126.901 27.732L125.305 28.082Z" fill="#1C1CA8" />
                            </svg></span>
                            <span className='no_btn'><svg xmlns="http://www.w3.org/2000/svg" width="241" height="52" viewBox="0 0 241 52" fill="none">
                                <rect width="241" height="52" rx="8" fill="#055CF5" fill-opacity="0.1" />
                                <path d="M120.74 31V21.074H118.808V27.64L114.678 21.074H112.27V31H114.202V23.958L118.71 31H120.74ZM125.776 29.516C124.866 29.516 124.026 28.844 124.026 27.556C124.026 26.254 124.866 25.596 125.776 25.596C126.686 25.596 127.526 26.254 127.526 27.556C127.526 28.858 126.686 29.516 125.776 29.516ZM125.776 23.902C123.718 23.902 122.164 25.428 122.164 27.556C122.164 29.67 123.718 31.21 125.776 31.21C127.834 31.21 129.388 29.67 129.388 27.556C129.388 25.428 127.834 23.902 125.776 23.902Z" fill="#1C1CA8" />
                            </svg></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
