import React, { useState } from "react";
import OkImg from "../../assets/images/ok_pic.png";
import OkImgMobile from "../../assets/images/ok_pic_mobile.png";
import ReCAPTCHA from "react-google-recaptcha";
import { useFormik } from "formik";
import * as Yup from "yup";
import Notification from "../../components/notification";
import axios from "../../utils/api";
import { Link } from "react-router-dom";

interface FormData {
  firstName: string;
  surname: string;
  email: string;
  contactNumber1: string;
  companyName: string;
  message: string;
}

const FormSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  surname: Yup.string().required("Surname is required"),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Email is required"),
  contactNumber1: Yup.string()
    .max(10, "Enter Valid Contact Number")
    .min(10, "Enter Valid Contact Number")
    .matches(new RegExp("^0[0-9]{9}.*$"), "Enter Valid Contact Number")
    .required("Contact number is required"),
  companyName: Yup.string().required("Company is required"),
  message: Yup.string().required("Message is required"),
});

export const SignupForm: React.FC = (props: any) => {
  const [isCheckReCaptcha, setIsCheckReCaptcha] = useState<boolean>(false);
  const [isReCaptcha, setIsReCaptcha] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isSystemError, setIsSystemError] = useState<boolean>(false);

  const proxyApiUrl = process.env.REACT_APP_PROXY_API_URL;
  const leadSendEmail = process.env.REACT_APP_LEAD_SEND_EMAIL;

  const formik = useFormik({
    validationSchema: FormSchema,
    initialValues: {
      firstName: "",
      surname: "",
      email: "",
      contactNumber1: "",
      companyName: "",
      message: "",
    },
    onSubmit: (value: FormData) => {
      setIsSubmitted(true);
      handleSubmit(value);
    },
  });

  const handleSubmit = (formData: FormData) => {
    if (isReCaptcha) {
      axios
        .post(`${proxyApiUrl}/lead`, {
          ...formData,
          typeId: 1,
          sentTo: leadSendEmail,
        })
        .then((data) => {
          console.log(data);
          if (data.status === 200) {
            props?.history.push("/signup-form-thank-you");
          }
        })
        .catch((error) => {
          console.log(error);
          setIsSystemError(true);
        });
    } else {
      setIsCheckReCaptcha(true);
    }
  };

  const onChange = (value: any) => {
    setIsReCaptcha(value);
    if (!value) {
      setIsCheckReCaptcha(true);
    } else {
      setIsCheckReCaptcha(false);
    }
  };

  return (
    <div className="header-section">
      {isSystemError && (
        <Notification
          type="warn"
          text="Sorry, we're experiencing problems with our system. Please try again in a few minutes."
        />
      )}
      <div className="partner_sign_up_form_new grid">
        <div className="partner_sign_up_form_first_row_new partner_sign_up_form_nav_new">
          <p className="partner_sign_up_breadcrumb">
            <Link to={"/corporate-partner"}>
              <span className="partner_sign_up_breadcrumb_first">
                Corporate partner
              </span>
            </Link>
            <span className="partner_sign_up_breadcrumb_second">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M6 3L11 8L6 13"
                  stroke="#FF4600"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Sign up your business
            </span>
          </p>
        </div>
        <div className="partner_sign_up_form_second_row_left_side_content_new">
          <div className="partner_sign_up_left_container">
            <p className="partner_sign_up_left_first_text">
              become a corporate partner
            </p>
            <p className="partner_sign_up_left_second_text">Let’s talk</p>
            <p className="partner_sign_up_left_third_text">
              If Go121 sounds like a good fit for your brand, fill in the form.
            </p>
            <div className="partner_sign_up_left_ok_img_container">
              <img
                src={OkImg}
                className="partner_sign_up_left_ok_img partner_sign_up_left_ok_img_desktop"
                alt="Okay"
              />
              <img
                src={OkImgMobile}
                className="partner_sign_up_left_ok_img partner_sign_up_left_ok_img_mobile"
                alt="Okay"
              />
            </div>
          </div>
        </div>

        <div className="partner_sign_up_form_second_row_right_side_content_new">
          <form action="" onSubmit={formik.handleSubmit}>
            <div className="partner_sign_up_form_container">
              <div className="partner_sign_up_form_header">
                <div className="partner_sign_up_form_title">
                  Sign up your business
                </div>
              </div>
              <div className="partner_sign_up_form_body">
                <div className="partner_sign_up_form_group">
                  <label
                    htmlFor=""
                    className={`partner_sign_up_form_label ${
                      formik.touched.firstName && formik.errors.firstName
                        ? "error"
                        : ""
                    }`}
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    className={`partner_sign_up_form_input`}
                    {...formik.getFieldProps("firstName")}
                  />
                  {formik.touched.firstName && formik.errors.firstName && (
                    <div className="personal_details_form_error">
                      {formik.errors.firstName}
                    </div>
                  )}
                </div>
                <div className="partner_sign_up_form_group">
                  <label
                    htmlFor=""
                    className={`partner_sign_up_form_label ${
                      formik.touched.surname && formik.errors.surname
                        ? "error"
                        : ""
                    }`}
                  >
                    Surname
                  </label>
                  <input
                    type="text"
                    className={`partner_sign_up_form_input`}
                    {...formik.getFieldProps("surname")}
                  />
                  {formik.touched.surname && formik.errors.surname && (
                    <div className="personal_details_form_error">
                      {formik.errors.surname}
                    </div>
                  )}
                </div>
                <div className="partner_sign_up_form_group">
                  <label
                    htmlFor=""
                    className={`partner_sign_up_form_label ${
                      formik.touched.companyName && formik.errors.companyName
                        ? "error"
                        : ""
                    }`}
                  >
                    Business name
                  </label>
                  <input
                    type="text"
                    className={`partner_sign_up_form_input`}
                    {...formik.getFieldProps("companyName")}
                  />
                  {formik.touched.companyName && formik.errors.companyName && (
                    <div className="personal_details_form_error">
                      {formik.errors.companyName}
                    </div>
                  )}
                </div>
                <div className="partner_sign_up_form_group">
                  <label
                    htmlFor=""
                    className={`partner_sign_up_form_label ${
                      formik.touched.email && formik.errors.email ? "error" : ""
                    }`}
                  >
                    Email address
                  </label>
                  <input
                    type="text"
                    className={`partner_sign_up_form_input`}
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="personal_details_form_error">
                      {formik.errors.email}
                    </div>
                  )}
                </div>
                <div className="partner_sign_up_form_group">
                  <label
                    htmlFor=""
                    className={`partner_sign_up_form_label ${
                      formik.touched.contactNumber1 &&
                      formik.errors.contactNumber1
                        ? "error"
                        : ""
                    }`}
                  >
                    Contact Number
                  </label>
                  <input
                    type="text"
                    className={`partner_sign_up_form_input`}
                    {...formik.getFieldProps("contactNumber1")}
                  />
                  {formik.touched.contactNumber1 &&
                    formik.errors.contactNumber1 && (
                      <div className="personal_details_form_error">
                        {formik.errors.contactNumber1}
                      </div>
                    )}
                </div>
                <div className="partner_sign_up_form_group">
                  <label
                    htmlFor=""
                    className={`partner_sign_up_form_label ${
                      formik.touched.message && formik.errors.message
                        ? "error"
                        : ""
                    }`}
                  >
                    Message
                  </label>
                  <input
                    type="text"
                    className={`partner_sign_up_form_input`}
                    {...formik.getFieldProps("message")}
                    placeholder="Type your message here"
                  />
                  {formik.touched.message && formik.errors.message && (
                    <div className="personal_details_form_error">
                      {formik.errors.message}
                    </div>
                  )}
                </div>
                <div className="partner_sign_up_form_group">
                  <div className="partner_sign_up_form_recaptcha_container">
                    <ReCAPTCHA
                      sitekey="6Lexn2UeAAAAAFY6T3jmZLrRbcwjeoeVK12M-KQB"
                      onChange={onChange}
                    />
                  </div>
                </div>
                {isSubmitted && isCheckReCaptcha && (
                  <div className="personal_details_form_error">
                    Please complete recaptcha
                  </div>
                )}
                <div className="partner_sign_up_form_group mb-0">
                  <button
                    type="submit"
                    className="partner_sign_up_form_submit_btn"
                    disabled={
                      formik.values.firstName === "" ||
                      formik.values.surname === "" ||
                      formik.errors.companyName ||
                      formik.errors.contactNumber1 ||
                      formik.errors.email ||
                      formik.errors.message
                        ? true
                        : false
                    }
                  >
                    <span>Next</span>
                    <svg
                      width="22"
                      height="24"
                      viewBox="0 0 22 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.88867 12H19.1109M19.1109 12L13.4969 6M19.1109 12L13.4969 18"
                        stroke="#FFBF00"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
