import React from 'react'
import cardFourthImg from '../../assets/images/about us/our_values_pic4.png';
import OurValuesRoundedCircleImg from '../../assets/images/about us/our_values_rounded_arrow.png';
import OurValuesRoundedCircleImgMobile from '../../assets/images/about us/our_values_rounded_arrowM.png';
import cardFifthImg from '../../assets/images/about us/our_values_pic5.png';
import cardSixthImg from '../../assets/images/about us/our_values_pic6.png';
import OurValuesBgCircleImg from '../../assets/images/about us/our_values_bg_circle.png';
import OurValuesLayerImg from '../../assets/images/about us/our_values_layer_pic.png';
import OurValuesLayerImgMobile from '../../assets/images/about us/our_values_layer_picM.png';

export const AboutUsCards = () => {
    return (
        <div className='about_us_our_values_bg_img'>
            <div className='about_us_cards grid'>
                <div className='about_us_cards_left_side_card first_row_left_side_card'>
                    <div className='about_us_card_left_side_card_box'>
                        <img src={cardFourthImg} alt="loading" />
                        <h3 className='about_us_cards_left_side_card_box_first_line font-large'>Our goal</h3>
                        <p className='about_us_cards_left_side_card_box_second_line font-small'>It’s all about 1-2-1 community engagement. We connect buyers and sellers through an agent-sales model. We do this by supporting our agents with training, technology and business skills that not only make their jobs safer, faster and easier but also provides them with an income that is sustainable and profitable. </p>
                    </div>
                    <div className='rounded_circle_arrow_img1'>
                        <img src={OurValuesRoundedCircleImg} alt="loading" />
                    </div>
                </div>

                <div className='bg_rounded_circle_img'>
                    <img src={OurValuesBgCircleImg} alt="loading" />
                </div>

                <div className='about_us_cards_right_side_two_cards'>
                    <div className='about_us_cards_right_side_first_box'>
                        <img src={cardFifthImg} alt="loading" />
                        <h3 className='about_us_cards_right_side_first_box_first_line font-large'>Our roots</h3>
                        <p className='about_us_cards_right_side_first_box_second_line font-small'>Go121 is part of the Flash Group, an innovative fintech dedicated to making people’s lives easier in the communities they serve. Flash Group is a Pepkor Holdings Limited Company.</p>
                    </div>
                    <div className='about_us_cards_right_side_second_box'>
                        <img src={cardSixthImg} alt="loading" />
                        <h3 className='about_us_cards_right_side_second_box_first_line font-large'>Our purpose</h3>
                        <p className='about_us_cards_right_side_second_box_second_line first_row_right_side_second_box_second_line font-small'>Is to create a trusted "1-2-1" connection in communities that delivers trusted products and services.</p>
                    </div>
                    <div className='rounded_circle_arrow_img2'>
                        <img src={OurValuesRoundedCircleImg} alt="laoding" />
                    </div>
                    <div className='rounded_circle_arrow_img2_mobile'>
                        <img src={OurValuesRoundedCircleImgMobile} alt="loading" />
                    </div>
                </div>

                <div className='our_values_layer_img'>
                    <img src={OurValuesLayerImg} alt="loading" className="our_values_layer_rounded_circle_arrow_img"/>
                </div>
                <div className='our_values_layer_img_mobile'>
                    <img src={OurValuesLayerImgMobile} alt="loading" className="our_values_layer_rounded_circle_arrow_img"/>
                </div>
            </div>
        </div>
    )
}
