import React from 'react'

export const OurStory = () => {
    return (
        <div className='header-section-white'>
            <div className='about_us_ourstory grid'>
               <div className='about_us_ourstory_center_content'>
                    <h1 className='about_us_ourstory_center_content_first_line font-mlarge'>Our story</h1>
                    <p className='about_us_ourstory_center_content_second_line font-small'>With extensive experience in the SIMs industry, we wanted to create a business that empowered agents and provided a more direct connection between brands and customers in the informal market. This created the opportunity for agents to offer a wider range of products and allow them to engage with customers in a targeted way, 1-2-1, in their own communities. </p>
               </div>
            </div>
        </div>
    )
}
