import React from 'react'
import productImg1 from '../../assets/images/verify an agent/catalogue_sales_pic.png';
import productImg2 from '../../assets/images/verify an agent/sim_cards_rica.png';
import productImg3 from '../../assets/images/verify an agent/surveys.png';
import productImg4 from '../../assets/images/verify an agent/bill_payments.png';
import productImg5 from '../../assets/images/verify an agent/airtime_sales.png';
import productImg6 from '../../assets/images/verify an agent/data_sales.png';
import productImg7 from '../../assets/images/verify an agent/electricity_sales.png';
import productImg8 from '../../assets/images/verify an agent/water_sales.png';
import badgeImg1 from '../../assets/images/verify an agent/badge1.png';
import badgeImg2 from '../../assets/images/verify an agent/badge2.png';
import badgeImg3 from '../../assets/images/verify an agent/badge3.png';
import badgeImg4 from '../../assets/images/verify an agent/badge4.png';
import squareProfilePicImg from '../../assets/images/verify an agent/square_profile_pic.png';


export const SiphoIsTrained = () => {
    return (
        <div className='header-section-white'>
            <div className='sipho_is_trained grid'>
                <div className='sipho_is_trained_left_side_content'>
                    <p className='sipho_is_trained_left_side_content_first_line font-xsmall'>Products and services</p>
                    <h2 className='sipho_is_trained_left_side_content_second_line font-large'>Sipho is trained to help you with the following:</h2>
                </div>
                <div className='sipho_is_trained_right_side_imgs'>
                    <div className='first_row_images'>
                        <div className='img'>
                            <img src={productImg1} alt="loading" />
                        </div>
                        <div className='img'>
                            <img src={productImg2} alt="loading" />
                        </div>
                        <div className='img'>
                            <img src={productImg3} alt="loading" />
                        </div>
                        <div className='img'>
                            <img src={productImg4} alt="loading" />
                        </div>
                    </div>
                    <div className='second_row_images'>
                        <div className='img'>
                            <img src={productImg5} alt="loading" />
                        </div>
                        <div className='img'>
                            <img src={productImg6} alt="loading" />
                        </div>
                        <div className='img'>
                            <img src={productImg7} alt="loading" />
                        </div>
                        <div className='img'>
                            <img src={productImg8} alt="loading" />
                        </div>
                    </div>
                </div>
                <div className='sipho_is_trained_floating_div'>
                    <div className='sipho_is_trained_floating_div_left_side_content'>
                        <div className='profile_pic'>
                            <img src={squareProfilePicImg} alt="loading" />
                        </div>
                        <div className='profile_info'>
                            <div className='box'>
                                <div className='profile_info_first_line font-xxsmall'>
                                    0123456789
                                </div>
                                <div className='profile_info_second_line font-large'>
                                    Sipho Nkosi
                                </div>
                                <div className='profile_info_third_line font-small'>
                                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4 22H7V11H4C3.46957 11 2.96086 11.2107 2.58579 11.5858C2.21071 11.9609 2 12.4696 2 13V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22Z" fill="white" />
                                        <path d="M7 11L11 2C11.7956 2 12.5587 2.31607 13.1213 2.87868C13.6839 3.44129 14 4.20435 14 5V9H19.66C19.9499 8.99672 20.2371 9.0565 20.5016 9.17522C20.7661 9.29393 21.0016 9.46873 21.1919 9.68751C21.3821 9.90629 21.5225 10.1638 21.6033 10.4423C21.6842 10.7207 21.7035 11.0134 21.66 11.3L20.28 20.3C20.2077 20.7769 19.9654 21.2116 19.5979 21.524C19.2304 21.8364 18.7623 22.0055 18.28 22H7M7 11V22M7 11H4C3.46957 11 2.96086 11.2107 2.58579 11.5858C2.21071 11.9609 2 12.4696 2 13V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H7" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg> <span>350 likes</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='sipho_is_trained_floating_div_right_side_imgs'>
                        <div className='img'>
                            <img src={badgeImg1} alt="loading" />
                        </div>
                        <div className='img'>
                            <img src={badgeImg2} alt="loading" />
                        </div>
                        <div className='img'>
                            <img src={badgeImg3} alt="loading" />
                        </div>
                        <div className='img'>
                            <img src={badgeImg4} alt="loading" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
