import React from 'react'
import RicaPolicyUpArrowImg from '../../assets/images/terms and conditions/terms_and_conditons_up_arrow.png';
import PrivacyAndPolicyGreatingImg from '../../assets/images/terms and conditions/rica_policy_arrow_pic.png';
import RicaPolicyUpArrowImgMobile from '../../assets/images/terms and conditions/terms_and_conditions_arrowM.png';
import PrivacyAndPolicyGreatingImgMobile from '../../assets/images/terms and conditions/rica_policy_arrow_picM.png';


export const RicaPolicyHeader = () => {
    return (
        <div className='header-section'>
            <div className='privacy_and_policy grid'>
                <div className='privacy_and_policy_up_arrow_img'>
                     <img src={RicaPolicyUpArrowImg} alt="loading" />
                </div>
                <div className='privacy_and_policy_up_arrow_img_mobile'>
                     <img src={RicaPolicyUpArrowImgMobile} alt="loading" />
                </div>
                <div className='privacy_and_policy_heading'>
                    <h1 className='privacy_and_policy_first_line'>RICA Policy</h1>
                </div>
                <div className='privacy_and_policy_greatings_img'>
                    <img src={PrivacyAndPolicyGreatingImg} alt="loading" />
                </div>
                <div className='privacy_and_policy_greatings_img_mobile'>
                    <img src={PrivacyAndPolicyGreatingImgMobile} alt="loading" />
                </div>
            </div>
        </div>
    )
}
