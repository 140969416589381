import React from 'react'
import { ContactContent } from '../../components/contact/contact-content'
import { ContactHeader } from '../../components/contact/contact-header'

export const Contact = () => {
  return (
    <div>
      <ContactHeader />
      <ContactContent />
    </div>
  )
}
