import React from 'react'
import { Link } from 'react-router-dom'
import desktopImg from '../../assets/images/Desktop.png';
// import GoToDashboardbtnI from '../../assets/images/go_to_dashboard_btn.png';

export const ApplicationFormComplete = () => {
    return (
        <div className='nabar-section'>
            <div className="application_form_complete_new grid">
                <div className="application_form_complete_new_content">
                    <img src={desktopImg} alt="loading" className='application_form_complete_img' />
                    <h1 className="application_form_complete_new_content_first_line font-mlarge">Hi {localStorage.getItem('username')}</h1>
                    <p className="application_form_complete_new_content_second_line font-medium">We’ve received your application to become a Go121 agent. Before you can start selling, you need to do a few tasks and training courses to become a Level 1 agent. Let’s get started!</p>
                    <button className='button_action go_to_dashboard_btn_width_height'>Go to dashboard
                        <span className='btn-w ms-4'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 24" fill="none">
                            <path d="M4.88892 12H19.1111M19.1111 12L13.4971 6M19.1111 12L13.4971 18" stroke="#FFBF00" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    )
}
