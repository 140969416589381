import React, { useEffect, useState } from 'react'
import FormMenImg from '../../assets/images/become an agent form/form_bg_desktop.png'
import FormMenImgMobile from '../../assets/images/become an agent form/form_bg_mobile.png'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Notification, { NotificationState } from '../../components/notification'
import axios from '../../utils/api'
import { Link, useLocation } from 'react-router-dom'
import { formatPhoneNumber } from '../../utils/formatstring'

const FormSchema = Yup.object({
  firstname: Yup.string()
    .min(2, 'First name should be at least 2 characters long')
    .required('First name is required'),
  surname: Yup.string()
    .min(2, 'Surname should be at least 2 characters long')
    .test(
      'no-same-firstname',
      'Surname cannot be same as first name',
      (value, context) => {
        return Boolean(
          value === undefined || (value && value !== context.parent.firstname),
        )
      },
    )
    .required('Surname is required'),
  cellphone: Yup.string()
    .max(10, 'Please enter a valid 10-digit number.')
    .min(10, 'Please enter a valid 10-digit number.')
    .matches(new RegExp('[0-9]{10}'), 'Please enter a valid 10-digit number.')
    .test(
      'no-leading-zero',
      'Cellphone number should start with 0',
      (value) => {
        return Boolean(value && value.startsWith('0'))
      },
    )
    .required('Please enter a valid 10-digit number.')
    .not(['0000000000'], 'Please enter a valid 10-digit number.'),
})

interface LocationState {
  firstname?: string
  surname?: string
  cellphone?: string
}

const PersonalDetailsForm: React.FC = (props: any) => {
  const location = useLocation<LocationState>()
  const proxyApi = process.env.REACT_APP_PROXY_API_URL
  const [agentID, setAgentID] = useState(null)
  const [notification, setNotification] = useState<NotificationState>({
    text: '',
    type: 'success',
    visible: false,
  })
  const [loading, setIsLoading] = useState<boolean>(false)
  const formik = useFormik({
    validationSchema: FormSchema,
    initialValues: {
      firstname: '',
      surname: '',
      cellphone: '',
    },
    initialStatus: false,
    onSubmit: (value) => {
      setIsLoading(true)
      if (agentID) {
        axios
          .put(`${proxyApi}api/proxy_put/Agent/${agentID}`, {
            firstName: value.firstname,
            surname: value.surname,
            cellphone: value.cellphone,
          })
          .then(({ data }) => {
            if (data.errorCode === 0) {
              setIsLoading(false)
              setNotification({
                text:
                  "Sorry, we're experiencing problems with our system. Please try again in a few minutes.",
                type: 'warn',
                visible: true,
              })
            } else {
              verifyAgent(data.code, 'success')
            }
          })
          .catch((error) => {
            setNotification({
              text:
                "Sorry, we're experiencing problems with our system. Please try again in a few minutes.",
              type: 'warn',
              visible: true,
            })
          })
      } else {
        axios
          .post(`${proxyApi}api/proxy_post/Agent`, {
            firstName: value.firstname,
            surname: value.surname,
            cellphone: value.cellphone,
          })
          .then(({ data }) => {
            setIsLoading(false)
            if (typeof data === 'object') {
              if (data.errorCode === 0) {
                verifyAgent(data.code, 'success')
              } else {
                switch (data.statusId) {
                  case 2:
                  case 3: {
                    setNotification({
                      text:
                        "An application already exists with those details. <a href='/cell-phone-input'>Continue with this application</a> or start a new application, using a different cellphone number.",
                      type: 'warn',
                      visible: true,
                    })
                    verifyAgent(data.code, 'statusID23')
                    break
                  }
                  case 4: {
                    setNotification({
                      text:
                        "An application already exists with those details. <a href='/cell-phone-input'>Continue with this application</a> or start a new application, using a different cellphone number.",
                      type: 'warn',
                      visible: true,
                    })
                    verifyAgent(data.code, 'statusID4')
                    break
                  }
                  case 6:
                  case 7:
                  case data.statusId < 7: {
                    setNotification({
                      text:
                        "Sorry, an agent already exists for that cellphone number. Please <a href='#'>log in</a> or contact the Call Centre for assistance.",
                      type: 'warn',
                      visible: true,
                    })
                    break
                  }
                  default:
                    return null
                }
              }
            } else {
              setNotification({
                text:
                  "Sorry, we're experiencing problems with our system. Please try again in a few minutes.",
                type: 'warn',
                visible: true,
              })
            }
          })
          .catch((error) => {
            setIsLoading(false)
            setNotification({
              text:
                "Sorry, we're experiencing problems with our system. Please try again in a few minutes.",
              type: 'warn',
              visible: true,
            })
          })
      }
    },
  })

  const verifyAgent = (
    code: any,
    type: 'success' | 'statusID4' | 'statusID23',
  ) => {
    axios
      .post(`${proxyApi}api/proxy_post/Agent/${code}/VerifyAgent`)
      .then(({ data }) => {
        setIsLoading(false)
        localStorage.removeItem('agentStatus')
        localStorage.setItem('username', data.firstName)
        localStorage.setItem('surname', data.surname)
        localStorage.setItem('phoneNumber', data.phoneNumber)
        localStorage.setItem('code', code)
        localStorage.setItem('agentId', data.agentId)
        localStorage.setItem('otpId', data.otpId)
        localStorage.setItem('isNew', 'true')
        if (type === 'success') {
          props?.history.push('/otp')
        } else if (type === 'statusID4') {
          localStorage.setItem('agentStatus', '4')
          setAgentID(data.agentId)
        }
      })
      .catch((error) => {
        setIsLoading(false)
        if (type === 'success') {
          setNotification({
            text:
              "Sorry, we're experiencing problems with our system. Please try again in a few minutes.",
            type: 'warn',
            visible: true,
          })
        }
      })
  }

  useEffect(() => {
    if (
      location.state?.firstname ||
      location.state?.surname ||
      location.state?.cellphone
    ) {
      formik.setValues({
        firstname: location.state?.firstname || '',
        surname: location.state?.surname || '',
        cellphone: location.state?.cellphone
          ? formatPhoneNumber(location.state?.cellphone, true)?.replaceAll(
              ' ',
              '',
            ) || ''
          : '',
      })
      // formik.setFieldValue('firstname', location.state?.firstname || '')
      // formik.setFieldValue('surname', location.state?.surname || '')
      // formik.setFieldValue(
      //   'cellphone',
      //   location.state?.cellphone
      //     ? formatPhoneNumber(location.state?.cellphone, true)?.replaceAll(
      //         ' ',
      //         '',
      //       )
      //     : '',
      // )
    }
  }, [])

  return (
    <div className="nabar-section">
      {notification.visible && (
        <Notification
          type={notification.type}
          text={notification.text}
          onClose={() => setNotification({ ...notification, visible: false })}
        />
      )}
      <div className="personal_details_form grid">
        <div className="personal_details_form_left_side_form">
          <p className="personal_details_breadcrumb">
            <Link to={'/become-go-121-agent'}>
              <span className="personal_details_breadcrumb_first">
                Become an Agent
              </span>
            </Link>
            <span className="personal_details_breadcrumb_second">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M6 3L11 8L6 13"
                  stroke="#FF4600"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Personal details
            </span>
          </p>
          <div className="personal_details_form_container">
            <form onSubmit={formik.handleSubmit}>
              <div className="personal_details_form_header">
                <div className="personal_details_form_title">
                  Personal details
                </div>
                <div className="personal_details_form_step">Step 1/3</div>
              </div>
              <div className="personal_details_form_body">
                <div className="personal_details_form_group">
                  <label
                    htmlFor=""
                    className={`personal_details_form_label ${
                      formik.touched.firstname && formik.errors.firstname
                        ? 'error'
                        : ''
                    }`}
                  >
                    First name
                  </label>
                  <input
                    type="text"
                    className={`personal_details_form_input ${
                      formik.touched.firstname && formik.errors.firstname
                        ? 'error'
                        : ''
                    }`}
                    {...formik.getFieldProps('firstname')}
                  />
                  {formik.touched.firstname && formik.errors.firstname && (
                    <div className="personal_details_form_error">
                      {formik.errors.firstname}
                    </div>
                  )}
                </div>
                <div className="personal_details_form_group">
                  <label
                    htmlFor=""
                    className={`personal_details_form_label ${
                      formik.touched.surname && formik.errors.surname
                        ? 'error'
                        : ''
                    }`}
                  >
                    Surname
                  </label>
                  <input
                    type="text"
                    className={`personal_details_form_input ${
                      formik.touched.surname && formik.errors.surname
                        ? 'error'
                        : ''
                    }`}
                    {...formik.getFieldProps('surname')}
                  />
                  {formik.touched.surname && formik.errors.surname && (
                    <div className="personal_details_form_error">
                      {formik.errors.surname}
                    </div>
                  )}
                </div>
                <div className="personal_details_form_group">
                  <label
                    htmlFor=""
                    className={`personal_details_form_label ${
                      formik.touched.cellphone && formik.errors.cellphone
                        ? 'error'
                        : ''
                    }`}
                  >
                    Cellphone number
                  </label>
                  <input
                    type="text"
                    className={`personal_details_form_input ${
                      formik.touched.cellphone && formik.errors.cellphone
                        ? 'error'
                        : ''
                    }`}
                    {...formik.getFieldProps('cellphone')}
                  />
                  {formik.touched.cellphone && formik.errors.cellphone && (
                    <div className="personal_details_form_error">
                      {formik.errors.cellphone}
                    </div>
                  )}
                </div>
                <div className="personal_details_form_group">
                  <button
                    type="submit"
                    className="personal_details_form_submit_btn"
                    disabled={!(formik.isValid && formik.dirty) || loading}
                  >
                    <span>Next</span>
                    {loading ? (
                      <svg
                        width="100"
                        height="100"
                        viewBox="0 0 100 100"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50C100 22.3913 77.6087 0 50 0C22.3913 0 0 22.3913 0 50M8.47826 50C8.47826 27.1739 26.9565 8.47826 50 8.47826C73.0435 8.47826 91.5217 27.1739 91.5217 50"
                          fill="white"
                        >
                          <animateTransform
                            attributeName="transform"
                            attributeType="XML"
                            type="rotate"
                            dur="1s"
                            from="0 50 50"
                            to="360 50 50"
                            repeatCount="indefinite"
                          />
                        </path>
                      </svg>
                    ) : (
                      <svg
                        width="22"
                        height="24"
                        viewBox="0 0 22 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.88867 12H19.1109M19.1109 12L13.4969 6M19.1109 12L13.4969 18"
                          stroke="#FFBF00"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  </button>
                </div>
                <div className="personal_details_extra_info">
                  <span>Already have an account? </span>
                  <a href="https://agent.instaccess.com">Sign in</a>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="personal_details_form_right_side_img">
          <img src={FormMenImg} alt="loading" />
        </div>
        <div className="personal_details_form_right_side_img_mobile">
          <img src={FormMenImgMobile} alt="loading" />
        </div>
      </div>
    </div>
  )
}

export { PersonalDetailsForm }
