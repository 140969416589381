import React from 'react'
import upArrowImg from '../../assets/images/what_is_go121/up_arrow.png';
import upArrowMobileViewImg from '../../assets/images/what_is_go121/up_arrow2.svg';
import downArrowImg from '../../assets/images/what_is_go121/down_arrow.png';
import womenImg from '../../assets/images/what_is_go121/women_pic_arrows.png';
import womenImgMobile from '../../assets/images/what_is_go121/women_pic_arrowM.png';


export const Header = () => {
    return (
        <div className='nabar-section'>
            <div className='what_is_go_header grid'>
                <div className='what_is_go_header_left_side_content'>
                    <p className='what_is_go_header_left_side_content_first_line font-xsmall'>what is go 121?</p>
                    <h1 className='what_is_go_header_left_side_content_second_line font-large'>We use a <span className='what_is_go_header_left_side_content_second_line_inside_content font-large'>direct 2 market sales model</span> to get the right brands into the right hands</h1>
                </div>

                <div className='women_img_styles'>
                    <img src={womenImg} alt="loading" />
                </div>
                <div className='women_img_styles_mobile'>
                    <img src={womenImgMobile} alt="loading" />
                </div>
            </div>
        </div>
    )
}
