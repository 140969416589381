import React from 'react'
import { Link } from 'react-router-dom'
import MobileIcon from '../../assets/images/contact/mobile.svg'

export const ContactContent: React.FC = () => {
  return (
    <div className="header-section-white">
      <div className="contact_content">
        <div className="contact_content_left">
          <div className="contact_content_label">Contact Us</div>
          <div className="contact_content_title">
            Call Centre
            <br /> Numbers
          </div>
          <Link
            to={'/help-centre'}
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            }}
            style={{ textDecoration: 'none' }}
          >
            <button
              className="button_action contact_content_visit_btn"
              type="submit"
            >
              Visit Help Centre
              <span className="btn-w">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 22 24"
                  fill="none"
                >
                  <path
                    d="M4.88892 12H19.1111M19.1111 12L13.4971 6M19.1111 12L13.4971 18"
                    stroke="#FFBF00"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </button>
          </Link>
        </div>
        <div className="contact_content_right">
          <a href="tel:083 903 5274" style={{ textDecoration: 'none' }}>
            <button className="contact_content_call_btn">
              <img
                src={MobileIcon}
                alt="Mobile"
                className="contact_content_call_btn_icon"
              />
              <div className="contact_content_call_btn_phone_number">
                083 903 5274
              </div>
              <div className="contact_content_call_btn_description">
                Free on MTN
              </div>
            </button>
          </a>
          <div className="contact_content_call_or">
            <div>or</div>
          </div>
          <a href="tel:083 903 5274" style={{ textDecoration: 'none' }}>
            <button className="contact_content_call_btn">
              <img
                src={MobileIcon}
                alt="Mobile"
                className="contact_content_call_btn_icon"
              />
              <div className="contact_content_call_btn_phone_number">
                083 903 5274
              </div>
              <div className="contact_content_call_btn_description">
                Free on CellC
              </div>
            </button>
          </a>
          <div className="contact_content_operating_hours">
            <div className="contact_content_operating_hours_title">
              Operating Hours
            </div>
            <div className="contact_content_operating_hours_body">
              <div className="contact_content_operating_hours_item">
                <span>Monday - Friday</span>
                <span>08h00 - 20h00</span>
              </div>
              <div className="contact_content_operating_hours_item">
                <span>Saturday</span>
                <span>09h00 - 18h00</span>
              </div>
              <div className="contact_content_operating_hours_item">
                <span>Sunday</span>
                <span>09h00 - 17h00</span>
              </div>
              <div className="contact_content_operating_hours_item">
                <span>Public Holidays</span>
                <span>09h00 - 17h00</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
