import React from "react";
import { useLocation } from "react-router-dom";
import { NavBar } from "./nav-bar";
import { Footer } from "./footer";
import { FooterCopyright } from "./footer-copyright";

interface ComponentProps {
  children?: React.ReactNode;
}

export const MainLayout: React.FC<ComponentProps> = ({ children }) => {
  const location = useLocation();

  return (
    <>
      {location.hash !== "#/nav-link" && <NavBar />}
      {children}
      {location.hash !== "#/nav-link" && location.hash !== "#/error-404" && (
        <Footer />
      )}
      {location.hash !== "#/nav-link" && location.hash !== "#/error-404" && (
        <FooterCopyright />
      )}
    </>
  );
};
