import React, { useEffect, useState } from 'react'
import { BrandLogo } from '../../constant/types';
import axios, { baseURL } from '../../utils/api';

export const OurBiggestBrands: React.FC = () => {
    const [brandLogos, setBrandLogos] = useState<BrandLogo[]>([]);
    const { REACT_APP_BECOME_PARTNER_LOGO_LIST_ENTITYID, REACT_APP_BECOME_PARTNER_LOGO_LIST_COUNTRYCODE, REACT_APP_BECOME_PARTNER_LOGO_LIST_PLATFORM } = process.env;

    useEffect(() => {
        axios.get(`api/Catalog/GetLogoList?EntityID=${REACT_APP_BECOME_PARTNER_LOGO_LIST_ENTITYID}&CountryCode=${REACT_APP_BECOME_PARTNER_LOGO_LIST_COUNTRYCODE}&Platform=${REACT_APP_BECOME_PARTNER_LOGO_LIST_PLATFORM}`).then(({ data }) => {
            setBrandLogos(data)
        })
    }, [])

    if (brandLogos.length > 0) {
        return (
            <div className='header-section-white'>
                <div className='our_biggest_brands grid'>
                    <div className='our_biggest_brands_first_row_content'>
                        <p className='our_biggest_brands_first_row_content_first_line font-xsmall'>Our partnership network</p>
                        <h1 className='our_biggest_brands_first_row_content_second_line font-large'>Join some of the biggest brands in SA</h1>
                    </div>
                    <div className='our_biggest_brands_container' style={{ gridTemplateColumns: `repeat(${brandLogos.length > 4 ? 5 : 4}, minmax(0, 1fr))` }}>
                        {brandLogos.map((logo) => (
                            <img src={`${baseURL}image/${logo.defaultWhite}`} alt={logo.name} />
                        ))}
                    </div>
                    <div className='our_biggest_brands_mobile_view_container'>
                        <div className="our_biggest_brands_mobile_view_img_container">
                            {brandLogos.map((logo) => (
                                <img src={`${baseURL}image/${logo.defaultWhite}`} alt={logo.name} />
                            ))}
                        </div>
                        <div className='left_right_arrow_img'>
                            <div className='left_side_arrow_pic'>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="none">
                                    <circle cx="25" cy="24.9999" r="25" transform="rotate(180 25 24.9999)" fill="#41AD49" />
                                    <rect width="24" height="24" transform="translate(37 36.9999) rotate(180)" fill="#41AD49" />
                                    <path d="M32.1111 24.9999L17.8889 24.9999M17.8889 24.9999L23.5029 30.9999M17.8889 24.9999L23.5029 18.9999" stroke="#FFBF00" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                            <div className='right_side_arrow_pic'>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="none">
                                    <circle cx="25" cy="24.9999" r="25" fill="#41AD49" />
                                    <rect width="24" height="24" transform="translate(13 12.9999)" fill="#41AD49" />
                                    <path d="M17.8889 24.9999H32.1111M32.1111 24.9999L26.4971 18.9999M32.1111 24.9999L26.4971 30.9999" stroke="#FFBF00" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return null;
    }
}
