import React from 'react'
import { HelpCenterSearchOnResultHeader } from '../../components/help-center-search-on-result/help-center-search-on-result-header';
// import { PopularSalesAgent } from '../../components/help-center-search-on-result/popular-sales-agent';
import { PopularSalesAgentBelowCards } from '../../components/help-center/popular-sales-agent-below-cards';
import { PopularSalesAgent } from '../../components/help-center/popular-sales-agent';


export const helpCenterSearchOnResult = () => {
    return (
        <div>
            <HelpCenterSearchOnResultHeader/>
            <PopularSalesAgent />
            <PopularSalesAgentBelowCards />
        </div>
    )
}
